.c-prose {
  &:global(.prose) {
    :where(a):not(:where([class~="not-prose"] *)) {
      @apply !text-secondary-500;
    }
  }
}

.legal-page-text table {
  @apply w-full overflow-y-auto block;
}

.legal-page-text a[data-cc-settings*="cookie-settings"] {
  @apply !text-primary-500 hover:!text-primary-600 cursor-pointer no-underline;
}
